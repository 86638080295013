import { useState, useEffect } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  CircularProgress as CircularProgressIcon,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '../button';
import { theme as inputTheme, Input } from '../input/Input';
import { optionsToComponents } from './options-to-components';
import { useQuery, useStateDebounce } from '../../hooks';

const theme = createTheme({
  components: {
    ...inputTheme.components,
    MuiSelect: {
      styleOverrides: {
        ...{
          root: {
            '&.has-header-component': {
              pointerEvents: 'none',
              visibility: 'hidden',
              position: 'absolute',
              right: 0,
              bottom: '-0.4rem',
              left: 0,
              overflow: 'hidden',
            },
            '&.has-header-component + button': {
              width: 'max-content',
            },
            '& div.MuiSelect-select.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
              paddingRight: '6.4rem',
            },
            '& button.MuiInputBase-inputAdornedEnd': {
              position: 'absolute',
              right: '2.4rem',
            },
            '&[data-size="large"] > div.MuiSelect-select': {
              lineHeight: '4.6rem',
            },
            '&[data-size="medium"] > div.MuiSelect-select': {
              lineHeight: '3.8rem',
            },
            '&[data-size="small"] > div.MuiSelect-select': {
              lineHeight: '3.4rem',
            },
          },
        },
        outlined: {
          minWidth: '10rem',
          height: 'inherit',

          '& ~ fieldset': {
            border: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'solid 0.1rem var(--gray-50)',
          width: 'min-content',

          '& ul': {
            display: 'grid',
            padding: 0,
            width: 'calc(100% + 0.2rem)',
            marginLeft: '-0.1rem',

            '& .MuiButtonBase-root': {
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateColumns: '1fr',
              alignContent: 'center',
              boxShadow: 'none',
              height: '4.4rem',
              lineHeight: 1,
              borderRadius: 0,
              justifyContent: 'left',
              padding: '1.2rem 1.6rem',
              textAlign: 'left',

              '&.MuiButtonBase-root:focus': {
                boxShadow: 'none',
              },

              '&.MuiButtonBase-root[data-disabled-aria-selected="false"],&.MuiButtonBase-root:not([data-disabled-aria-selected])':
                {
                  '&[data-aria-selected="false"][aria-selected="true"],&[data-aria-selected="true"]':
                    {
                      gridTemplateColumns: '1fr max-content',
                      backgroundColor: 'var(--primary-40)',
                    },
                },
            },
            '& div.MuiInputBase-root.MuiInputBase-formControl': {
              height: '4.4rem',
            },
            '&.Mui-focused .MuiButtonBase-root': {
              backgroundColor: 'var(--primary-40)',
            },
          },
        },
      },
    },
  },
});

interface SelectBaseProps extends MuiSelectProps {
  label?: string;
  options?: Array<any>;
  'data-size'?: 'small' | 'medium' | 'large';
  HeaderComponent?: React.ReactNode;
  onToggle?: () => void;
  onClear?: (obj: any) => void;
  helperText?: string;
  error?: boolean;
  withClearButton?: boolean;
}

const SelectBase: React.FC<SelectBaseProps> = ({
  label = '',
  id = '',
  options = [],
  className = '',
  'data-size': dataSize = 'medium',
  placeholder,
  HeaderComponent = null,
  value,
  children,
  maxRows = 5,
  onClick,
  onChange,
  onToggle,
  onClear,
  helperText,
  withClearButton,
  MenuProps,
  ...props
}: SelectBaseProps) => {
  const headerComponentClassName = HeaderComponent ? 'has-header-component' : '';

  const handleOnClick = (e: any) => {
    if (onClick) onClick(e);

    if (!HeaderComponent && !props.open) {
      onToggle && onToggle();
    }
  };

  const handleOnChange = (e: any, child: any) => {
    if (withClearButton && e.target.value === value) e.target.value = '';

    onChange && onChange(e, child);
  };

  const handleOnClear = () => {
    if (onClear) {
      onClear({ id: '', label: '' });
      return;
    }

    let emptyValue: any = { target: { value: '' } };

    if (onChange) {
      onChange(emptyValue, false);
      return;
    }

    emptyValue = '';
    props.onSelect && props.onSelect(emptyValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        {label && (
          <InputLabel shrink id={id || label}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          labelId={id || label}
          value={value}
          className={`MuiSelect-root ${className} ${headerComponentClassName}`}
          data-size={dataSize}
          MenuProps={{
            ...MenuProps,
            PaperProps: {
              style: {
                maxHeight: Number(maxRows) * 44 + 2,
                overflowY: 'auto',
                ...(MenuProps && MenuProps.PaperProps && MenuProps.PaperProps.style
                  ? MenuProps.PaperProps.style
                  : {}),
              },
            },
          }}
          endAdornment={
            value && withClearButton ? (
              <IconButton className={'MuiInputBase-inputAdornedEnd'} onClick={handleOnClear}>
                <ClearIcon />
              </IconButton>
            ) : null
          }
          onClick={handleOnClick}
          onClose={props.onClose || onToggle}
          onChange={handleOnChange}
          {...props}
          displayEmpty
          renderValue={(selected) => {
            if (typeof selected !== 'boolean' && !selected)
              return <>{placeholder || 'Select option'}</>;

            return <>{options.find((option) => option?.value === selected)?.label || selected}</>;
          }}
        >
          {children}
        </MuiSelect>
        {HeaderComponent}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </ThemeProvider>
  );
};

interface SelectProps extends SelectBaseProps {
  options?: Array<any>;
  value?: any;
  onChange?: (e: any) => void;
  disabled?: boolean;
  withClearButton?: boolean;
  helperText?: string;
  selectedAreasEqaul?: { key?: string; keys: Array<string> };
  children?: any;
  [key: string]: any;
}

export const Select: React.FC<SelectProps> = ({
  options = [],
  selectedAreasEqaul,
  children,
  ...props
}: SelectProps) => {
  return (
    <SelectBase options={options} {...props}>
      {children}
      {!children && optionsToComponents(options, { selectedAreasEqaul, ...props })}
    </SelectBase>
  );
};

export interface CustomHeaderSelectProps extends SelectBaseProps {
  loading?: boolean;
  open?: boolean;
  onSelect?: (e: any) => void;
  children?: any;
  helperText?: string;
  error?: boolean;
  [key: string]: any;
}

export const CustomHeaderSelect: React.FC<CustomHeaderSelectProps> = ({
  options = [],
  onSelect,
  children,
  ...props
}: CustomHeaderSelectProps) => {
  return (
    <SelectBase value={''} {...props}>
      {children}
      {optionsToComponents(options, { ...props, onSelect, disabledAriaSelected: true })}
    </SelectBase>
  );
};

interface SearchableSelectProps extends SelectProps {
  selectedOptionLabel?: string;
  queryKey?: string | string[];
  queryFn?: (prop: any) => any;
  withNewOption?: boolean;
  helperText?: string;
  selectedAreasEqaul?: { key?: string; keys: Array<string> };
  multiple?: boolean;
  onSelect?: (e: any) => void;
  open?: boolean;
  InputComponent?: any;
}

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options = [],
  onSelect,
  selectedOptionLabel = '',
  withNewOption,
  queryKey = '',
  queryFn = () => async () => [],
  selectedAreasEqaul,
  InputComponent = Input,
  ...props
}: SearchableSelectProps) => {
  const [inputValue, setInputValue, loadingDebounce] = useStateDebounce('', 1000, true);
  const [inputRef, setInputRef] = useState<any>();
  const { data = [], isFetching } = useQuery<any>(
    typeof queryKey !== 'string'
      ? ['key', JSON.stringify({ ...queryKey }), inputValue]
      : ['key', queryKey, inputValue],
    props.disabled ? () => [] : queryFn(inputValue),
  );

  const getBaseOptions = () => {
    const defaultOption = {
      label: withNewOption && props.open ? inputValue || selectedOptionLabel : selectedOptionLabel,
      isEmpty: true,
      withNewOption,
      id: 'none',
      ...(props.multiple ? { value: 'multiple' } : { value: selectedOptionLabel }),
    };
    const baseOptions = [...options, ...data].length
      ? [...options, ...data, { ...defaultOption, withNewOption: false }]
      : [defaultOption];

    return baseOptions;
  };

  useEffect(() => {
    if (typeof props.open === 'boolean' && !props.open) return;
    if (!inputRef) {
      setInputValue('', true);
      return;
    }

    inputRef.focus();
  }, [props.open, inputRef]);

  useEffect(() => {
    if (!inputRef) return;

    inputRef.focus();
  }, [inputValue]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  return (
    <SelectBase
      value={props.multiple ? [selectedOptionLabel] : selectedOptionLabel}
      {...props}
      onClear={onSelect}
    >
      <InputComponent
        endIcon={(isFetching || loadingDebounce) && <CircularProgressIcon size={24} />}
        placeholder={'Search...'}
        withoutBorder
        key={'input-search-key'}
        value={inputValue}
        inputRef={setInputRef}
        onChange={handleInputChange}
        onKeyDown={(e: any) => {
          e.stopPropagation();
        }}
      />
      {optionsToComponents(
        getBaseOptions(),
        { ...props, onSelect, selectedAreasEqaul },
        inputValue,
      )}
    </SelectBase>
  );
};
