import React from 'react';
import styled, { css } from 'styled-components';
import moment, { Moment } from 'moment';
import { DatePicker, TableFilters, BelongsToFilterSelects } from '../../design-system';
import { showToRoles } from '../../utils';
import { FiltersSetStatesProp, User } from '../../types';

interface TableFiltersBaseProps {
  isNormalOrder: boolean;
}

const TableFiltersBase = styled(TableFilters)<TableFiltersBaseProps>`
  & > * {
    order: 2;
  }

  ${(props) =>
    !props.isNormalOrder &&
    css`
      & > *:nth-child(4) {
        order: 1;
      }
    `}
`;

interface Props {
  user: User;
  startDate?: Moment;
  endDate?: Moment;
  isSubmitClicked?: boolean;
  setStates: FiltersSetStatesProp;
}

export const Filters: React.FC<Props> = ({
  user,
  startDate,
  endDate,
  isSubmitClicked,
  setStates,
}: Props) => {
  return (
    <TableFiltersBase
      isNormalOrder={
        !!showToRoles(
          ['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE', 'CLIENT_MANAGER', 'CLIENT_DISPATCHER'],
          user,
        )
      }
    >
      <BelongsToFilterSelects
        handleChange={(obj) => {
          setStates('force', obj);
        }}
        isYardRequired={!!showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)}
        isClientRequired
        isSubmitClicked={isSubmitClicked}
      />
      <DatePicker
        label={'From'}
        data-size={'medium'}
        defaultValue={startDate}
        onChange={(e) => setStates('force', { startDate: e })}
        maxDate={endDate ? moment(endDate) : moment()}
      />
      <DatePicker
        label={'To'}
        data-size={'medium'}
        defaultValue={endDate}
        onChange={(e) => setStates('force', { endDate: e })}
        minDate={startDate ? moment(startDate) : null}
        maxDate={moment()}
      />
    </TableFiltersBase>
  );
};
