import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  ReportsPage,
  ReportsHeader,
  ReportsPageInfos,
  ReportsGeneralInfo,
  ReportsPriceInfo,
  ReportsBody,
} from './ReportsComponents';
import { useStore } from '../../context';
import {
  useStatesWithFilterCounter,
  useReportsGetContainersQuery,
  useSaveFilters,
} from '../../hooks';
import { exportExcel, formatContainerType, momentTextFormat, showToRoles } from '../../utils';

export const Reports = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  if (showToRoles('client', user)) return <Navigate to={'/dashboard/containers'} replace />;

  const [oldFilters] = useSaveFilters('belongs-to', false);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter({
    yardCompanyId: '',
    country: '',
    city: '',
    yardId: '',
    clientId: '',
    startDate: null,
    endDate: null,
  });

  const { data: { data, totalCount } = { data: [], totalCount: 0 }, refetch } =
    useReportsGetContainersQuery({
      ...filteredStates,
    });

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const handleExportExcelClick = () => {
    let fileName = oldFilters.clientName;
    let startDate = states.startDate ? momentTextFormat(states.startDate, user, true) : '';
    let endDate = states.endDate ? momentTextFormat(states.endDate, user, true) : '';

    const excelData = data.map((container) => ({
      ['Container Number']: container.number,
      ['Container Type']: formatContainerType(container.type),
      ['Container Content']: container.isLoadedText,
      ['Gate In Date']: momentTextFormat(container.gateIn.completedAt, user, true),
      ['Gate Out Date']: container.gateOut
        ? momentTextFormat(container.gateOut.completedAt as string, user, true)
        : '',
      ['Days In Terminal']: container.daysInTerminal,
      ['Free Days']: container.freeDays,
      ['Days In Terminal Price']: container.daysInTerminalPrice,
      ['Crane In Price']: container.gateInCranePrice,
      ['Crane Out Price']: container.gateOutCranePrice,
    }));
    const filters = [
      {
        Client: oldFilters.clientName,
        Country: states.country,
        City: states.city,
        Yard: oldFilters.yardName,
        From: startDate,
        To: endDate,
      },
    ];

    const sheets = [
      { data: excelData, sheetName: 'reports' },
      { data: filters, sheetName: 'filters' },
    ];

    if (startDate || endDate) {
      startDate = startDate || 'start';
      endDate = endDate || 'end';
      fileName = `${fileName} ${startDate}__${endDate}`;
    }

    exportExcel({ sheets, fileName });
  };

  const handleShowReportsClick = () => {
    setIsSubmitClicked(true);
    if (!String(states.clientId).length) return;

    refetch();
  };

  return (
    <ReportsPage innerScroll={!data.length}>
      <ReportsHeader
        totalCount={totalCount}
        handleExportExcelClick={handleExportExcelClick}
        handleShowReportsClick={handleShowReportsClick}
      />
      {!!data.length && (
        <ReportsPageInfos>
          <ReportsGeneralInfo user={user} {...states} />
          <ReportsPriceInfo data={data} />
        </ReportsPageInfos>
      )}
      <ReportsBody
        user={user}
        data={data}
        isSubmitClicked={isSubmitClicked}
        states={states}
        setStates={setStates}
      />
    </ReportsPage>
  );
};
