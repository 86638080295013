import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import validator from 'validator';
import {
  Typography,
  Button,
  Input,
  InputContainer,
  Select,
  DatePicker,
  BelongsToSelects,
  enqueueSnackbar,
} from '../../design-system';
import {
  DashboardHeader,
  DashboardForm,
  DashboardFormCustomColumn,
  DashboardFormTwoColumn,
} from '../dashboard';
import { useStore } from '../../context';
import { useAddContainerMutation } from '../../hooks';
import { containerValidator, showToRoles, getNormalFormProps } from '../../utils';
import { User } from '../../types';
import { containerTypes, containerContents, MESSAGES, INPUT_ERRORS, REGEX } from '../../const';

let formProps: any = {};

const getSubmitProps = (user: User) => {
  const props: any = {
    containerNumber: formProps.containerNumber,
    clientId: formProps.clientId,
    yardId: formProps.yardId,
  };

  const errors: any = {
    completedAt:
      !showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) ||
      moment(formProps.completedAt).set('hour', 0).isBefore(moment().set('hour', 1)),
    hasCompletedAt:
      !showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) ||
      !!formProps.completedAt,
  };
  const validators: any = {
    containerNumber: containerValidator(formProps.containerNumber),
  };

  if (
    [
      ...Object.values(props),
      ...Object.values(errors),
      ...Object.values(validators),
      ...Object.values(formProps.validators).map((val) => !val),
    ].some((val) => !val)
  ) {
    return null;
  }

  delete formProps.isSubmitClicked;
  if (!showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user))
    delete formProps.completedAt;
  if (showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user))
    formProps.completedAt = moment(formProps.completedAt).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  return getNormalFormProps(formProps);
};

export const ContainersAddHeader = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const navigate = useNavigate();
  const { mutate } = useAddContainerMutation();

  const handleSubmitClick = () => {
    formProps.onSubmitClick();
    const props = getSubmitProps(user);
    if (!props) return;

    mutate(props, {
      onSuccess: () => {
        navigate('/dashboard/containers');
      },
      onError: (err: any) => {
        if (err.statusCode === 409) enqueueSnackbar(MESSAGES.CONTAINERS.ERROR.DUPLICATED, 'error');
      },
    });
  };

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Add Container
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
      <Button size={'medium'} onClick={handleSubmitClick}>
        Submit
      </Button>
    </DashboardHeader>
  );
};

export const ContainersAddForm = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const [yardCompanyName, setYardCompanyName] = useState();
  const yardCompanyId = useRef();
  const [yardName, setYardName] = useState();
  const yardId = useRef();
  const [clientName, setClientName] = useState();
  const clientId = useRef();
  const [containerNumber, setContainerNumber] = useState('');
  const [driverFirstName, setDriverFirstName] = useState('');
  const [driverLastName, setDriverLastName] = useState('');
  const [licensePlateNumber, setLicensePlateNumber] = useState('');

  const [containerType, setContainerType] = useState(containerTypes[0].value);
  const [containerContent, setContainerContent] = useState(containerContents[0].value);
  const [completedAt, setCompletedAt] = useState();
  const [, setUpdate] = useState<any>();

  const validators = {
    driverFirstName:
      !!driverFirstName &&
      (!validator.isLength(driverFirstName, { max: 30 }) || !REGEX.USER_NAME.test(driverFirstName)),
    driverLastName:
      !!driverLastName &&
      (!validator.isLength(driverLastName, { max: 30 }) || !REGEX.USER_NAME.test(driverLastName)),
    licensePlateNumber: licensePlateNumber
      ? !validator.isLength(licensePlateNumber, { min: 4, max: 10 })
        ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER_LENGTH
        : !REGEX.LICENSE_PLATE_NUMBER.test(licensePlateNumber)
        ? INPUT_ERRORS.NOT_VALID.LICENSE_PLATE_NUMBER
        : ''
      : '',
  };

  useEffect(() => {
    if (!formProps) return;

    formProps.isSubmitClicked = false;
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    formProps = {
      ...formProps,
      containerNumber,
      yardId: yardId.current,
      clientId: clientId.current,
      containerType,
      isLoaded: containerContent,
      completedAt,
      driverFirstName,
      driverLastName,
      licensePlateNumber,
      validators,
      onSubmitClick: () => {
        formProps.isSubmitClicked = true;
        setUpdate(Math.random());
      },
    };
  }, [
    containerNumber,
    yardCompanyName,
    yardCompanyId.current,
    yardName,
    yardId.current,
    clientName,
    clientId.current,
    containerType,
    containerContent,
    completedAt,
    driverFirstName,
    driverLastName,
    licensePlateNumber,
  ]);

  return (
    <DashboardForm paperSize={'full-size'}>
      <BelongsToSelects
        handleYardCompanyChange={(obj) => {
          yardCompanyId.current = obj.id;
          setYardCompanyName(obj.label);
        }}
        handleYardChange={(obj) => {
          yardId.current = obj.id;
          setYardName(obj.label);
        }}
        handleClientChange={(obj) => {
          clientId.current = obj.id;
          setClientName(obj.label);
        }}
        isSubmitClicked={formProps.isSubmitClicked}
      />
      <DashboardFormCustomColumn columns={'1fr 1fr 1fr'}>
        <InputContainer
          label={'Container Number*'}
          placeholder={'Container Number'}
          data-size={'medium'}
          defaultValue={containerNumber}
          onChange={(e) => setContainerNumber(e.target.value)}
          error={
            !!containerNumber && !containerValidator(containerNumber)
              ? true
              : formProps.isSubmitClicked && !containerNumber
          }
          helperText={
            !!containerNumber && !containerValidator(containerNumber)
              ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER
              : formProps.isSubmitClicked && !containerNumber
              ? INPUT_ERRORS.REQUIRED.CONTAINER_NUMBER
              : ''
          }
        />
        <Select
          label={'Container Type*'}
          options={containerTypes}
          value={containerType}
          onChange={(e) => setContainerType(e.target.value)}
        />
        <Select
          label={'Container Content'}
          options={containerContents}
          value={containerContent}
          onChange={(e) => setContainerContent(e.target.value)}
        />
      </DashboardFormCustomColumn>
      <DashboardFormTwoColumn>
        {showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) && (
          <DatePicker
            label={'Gated In Date*'}
            data-size={'medium'}
            defaultValue={completedAt}
            onChange={setCompletedAt}
            maxDate={moment()}
            error={formProps.isSubmitClicked && !completedAt}
            helperText={formProps.isSubmitClicked && !completedAt && INPUT_ERRORS.REQUIRED.DATE}
          />
        )}
        <Input
          label={`License Plate Number`}
          placeholder={'License Plate Number'}
          data-size={'medium'}
          defaultValue={licensePlateNumber}
          onChange={(e) => setLicensePlateNumber(e.target.value)}
          error={!!validators.licensePlateNumber}
          helperText={validators.licensePlateNumber}
        />
      </DashboardFormTwoColumn>
      <DashboardFormTwoColumn>
        <Input
          label={`Driver First Name`}
          placeholder={'Driver First Name'}
          data-size={'medium'}
          defaultValue={driverFirstName}
          onChange={(e) => setDriverFirstName(e.target.value)}
          error={validators.driverFirstName}
          helperText={validators.driverFirstName ? INPUT_ERRORS.NOT_VALID.DRIVER_NAME : ''}
        />
        <Input
          label={`Driver Last Name`}
          placeholder={'Driver Last Name'}
          data-size={'medium'}
          defaultValue={driverLastName}
          onChange={(e) => setDriverLastName(e.target.value)}
          error={validators.driverLastName}
          helperText={validators.driverLastName ? INPUT_ERRORS.NOT_VALID.DRIVER_NAME : ''}
        />
      </DashboardFormTwoColumn>
    </DashboardForm>
  );
};
