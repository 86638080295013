import axios from 'axios';
import { ReportsGetContainersQueryProps, ReportsGetContainersResponse } from '../../types';

class ReportsService {
  getContainers = (props: ReportsGetContainersQueryProps) =>
    axios.get<ReportsGetContainersResponse>('/reports/containers', {
      params: props,
    });
}

export const reportsService = new ReportsService();
