import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Typography } from '../typography';
import { Button } from '../button';
import { InputContainer } from '../input';
import { RadioLabel } from '../radio';
import { ModalBase, ModalWrapper, ModalFooterButtons, ModalBodyInputs } from './ModalComponents';
import { containerValidator } from '../../utils';
import { useStateDebounce } from '../../hooks';
import { INPUT_ERRORS, containerTypes } from '../../const';

const Radios = styled(ModalBodyInputs)`
  grid-template-columns: repeat(5, max-content);
  grid-auto-flow: column;
`;

export const containerListModal: any = {};

export const ContainerListModal = () => {
  const [open, setOpen] = useState(false);
  const [containerNumber, setContainerNumber] = useState<any>([]);
  const [containerType, setContainerType] = useState<any>([]);
  const [containers, setContainers, isLoading] = useStateDebounce('', 1000, true);

  const isContainersValid = useMemo(() => {
    const isValid = containers.split('\n').every((line: any) => {
      line = line.trim();

      if (!line) return true;
      if (containerValidator(line)) return true;
    });

    return !containers || isValid;
  }, [containers]);

  useEffect(() => {
    const newContainerNumber: any = [];
    const lines = containers.split('\n');
    lines.forEach((line: any) => {
      line = line.trim();

      if (!line) return;
      if (!containerValidator(line)) return;

      newContainerNumber.push(line);
    });

    if (!newContainerNumber.length) {
      newContainerNumber.push('');
    }
    setContainerNumber(newContainerNumber);
  }, [containers]);

  useEffect(() => {
    containerListModal.open = () => {
      setContainerNumber([]);
      setContainerType('');
      setContainers('', true);

      setOpen(true);
    };
  }, []);

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e: any) => {
    setContainers(e.target.value);
  };

  const handleContainerTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContainerType(e.target.value);
  };

  const handleSubmitClick = async () => {
    containerListModal?.fc({
      containerNumber,
      containerType: new Array(containerNumber.length).fill(containerType),
      containerContent: new Array(containerNumber.length).fill(false),
    });

    setOpen(false);
  };

  return (
    <ModalBase
      id={'container-list-modal'}
      open={open}
      onClose={handleOnClose}
      paperSize={'max-content'}
    >
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          Container List
        </Typography>
        <Radios>
          {[{ label: 'Mixed', value: '' }, ...containerTypes].map((type) => (
            <RadioLabel
              key={type.value}
              checked={containerType === type.value}
              onChange={handleContainerTypeChange}
              value={type.value}
              name={'container-types'}
              label={type.label}
            />
          ))}
        </Radios>
        <InputContainer
          label={'Container Numbers'}
          placeholder={'AAAZ1234567\nAAAZ1234567\nAAAZ1234567\nAAAZ1234567'}
          data-size={'medium'}
          rows={4}
          multiline
          defaultValue={containers}
          onChange={handleOnChange}
          error={!isContainersValid}
          helperText={!isContainersValid ? INPUT_ERRORS.NOT_VALID.CONTAINERS : ''}
        />
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            size={'medium'}
            data-color={'primary'}
            onClick={handleSubmitClick}
            disabled={!isContainersValid || isLoading}
          >
            Add
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
