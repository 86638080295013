import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  WorkOutlineOutlined as WorkOutlineOutlinedIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  Apartment as ApartmentIcon,
  Explore as ExploreIcon,
  Business as BusinessIcon,
  PeopleAlt as PeopleAltIcon,
  AccountCircle as AccountCircleIcon,
  PriceChange as PriceChangeIcon,
  RequestQuote as RequestQuoteIcon,
} from '@mui/icons-material';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import {
  DrawerBase as Drawer,
  DrawerBody,
  DrawerCategoryTitle,
  DrawerButton,
  DrawerControlButton,
  Logo,
  enqueueSnackbar,
} from '../../design-system';
import { useStore } from '../../context';
import { showToRoles } from '../../utils';
import { useLogoutMutation } from '../../hooks';
import { MESSAGES } from '../../const';

const DashboardDrawerBase = styled(Drawer)`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
`;

export const DashboardDrawer = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mutate } = useLogoutMutation();
  const [isOpen, setIsOpen] = useState(
    typeof Cookies.get('dashboard-drawer-is-open') !== 'undefined'
      ? !!Cookies.get('dashboard-drawer-is-open')
      : true,
  );

  const handleChangeDrawer = () => {
    setIsOpen(!isOpen);
    Cookies.set('dashboard-drawer-is-open', !isOpen ? 'true' : '');
  };

  const handleLogoutClick = async () => {
    await mutate(undefined, {
      onSuccess: () => {
        enqueueSnackbar(MESSAGES.AUTH.SUCCESS.LOG_OUT, 'success');
        navigate('/signin');
      },
    });
  };

  return (
    <DashboardDrawerBase isOpen={isOpen}>
      <Logo isOpen={isOpen} />
      <DrawerBody>
        <DrawerCategoryTitle isOpen={isOpen}>DASHBOARD</DrawerCategoryTitle>
        <DrawerButton
          isOpen={isOpen}
          to={'/dashboard/containers'}
          startIcon={<WorkOutlineOutlinedIcon />}
          isActive={pathname.includes('/dashboard/containers')}
        >
          Containers
        </DrawerButton>
        <DrawerButton
          isOpen={isOpen}
          to={'/dashboard/gateins'}
          startIcon={<ArrowForwardIcon />}
          isActive={pathname.includes('/dashboard/gateins')}
        >
          Gate-ins
        </DrawerButton>
        <DrawerButton
          isOpen={isOpen}
          to={'/dashboard/gateouts'}
          startIcon={<ArrowBackIcon />}
          isActive={pathname.includes('/dashboard/gateouts')}
        >
          Gate-outs
        </DrawerButton>
        <DrawerButton
          isOpen={isOpen}
          to={'/dashboard/storage-pricing'}
          startIcon={<PriceChangeIcon />}
          isActive={pathname.includes('/dashboard/storage-pricing')}
        >
          Storage Pricings
        </DrawerButton>
        {showToRoles('admin', user) && (
          <DrawerButton
            isOpen={isOpen}
            to={'/dashboard/reports'}
            startIcon={<RequestQuoteIcon />}
            isActive={pathname.includes('/dashboard/reports')}
          >
            Reports
          </DrawerButton>
        )}
        <DrawerCategoryTitle isOpen={isOpen}>ACCESS CONTROL</DrawerCategoryTitle>
        {showToRoles(['SUPER_ADMIN'], user) && (
          <DrawerButton
            isOpen={isOpen}
            to={'/dashboard/yardcompanies'}
            startIcon={<ApartmentIcon />}
            isActive={pathname.includes('/dashboard/yardcompanies')}
          >
            Yard Companies
          </DrawerButton>
        )}
        {showToRoles(['SUPER_ADMIN'], user) && (
          <DrawerButton
            isOpen={isOpen}
            to={'/dashboard/yards'}
            startIcon={<ExploreIcon />}
            isActive={pathname.includes('/dashboard/yards')}
          >
            Yards
          </DrawerButton>
        )}
        {showToRoles(['SUPER_ADMIN'], user) && (
          <DrawerButton
            isOpen={isOpen}
            to={'/dashboard/clients'}
            startIcon={<BusinessIcon />}
            isActive={pathname.includes('/dashboard/clients')}
          >
            Clients
          </DrawerButton>
        )}
        {showToRoles(
          ['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_MANAGER', 'CLIENT_MANAGER'],
          user,
        ) && (
          <DrawerButton
            isOpen={isOpen}
            to={'/dashboard/employees'}
            startIcon={<PeopleAltIcon />}
            isActive={pathname.includes('/dashboard/employees')}
          >
            Employees
          </DrawerButton>
        )}
        <DrawerButton
          isOpen={isOpen}
          to={'/dashboard/account'}
          startIcon={<AccountCircleIcon />}
          isActive={pathname.includes('/dashboard/account')}
        >
          Account
        </DrawerButton>
        <DrawerButton
          isOpen={isOpen}
          startIcon={<LogoutOutlinedIcon />}
          isActive={false}
          onClick={handleLogoutClick}
        >
          Log out
        </DrawerButton>
      </DrawerBody>
      <DrawerControlButton isOpen={isOpen} handleChangeDrawer={handleChangeDrawer} />
    </DashboardDrawerBase>
  );
};
