import { useState, useRef, useEffect } from 'react';
import { sendContractModal } from '../modal';
import { SearchableSelect } from '.';
import { useStore } from '../../context';
import { useSaveFilters, useYardOptionsQuery } from '../../hooks';
import { showToRoles } from '../../utils';
import { belongsToOptions, clientYardService } from '../../services';

interface BelongsToFilterSelectsProps {
  handleChange: (obj: any) => void;
  isYardRequired?: boolean;
  isClientRequired?: boolean;
  isSubmitClicked?: boolean;
}

export const BelongsToFilterSelects: React.FC<BelongsToFilterSelectsProps> = ({
  handleChange,
  isYardRequired,
  isClientRequired,
  isSubmitClicked,
}: BelongsToFilterSelectsProps) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  const [oldFilters, setOldFilters] = useSaveFilters('belongs-to', true);
  const [, setYardCompanyName] = useState(oldFilters.yardCompanyName);
  const yardCompanyId = useRef(oldFilters.yardCompanyId);
  const [country, setCountry] = useState(oldFilters.country);
  const [city, setCity] = useState(oldFilters.city);
  const [yardName, setYardName] = useState(oldFilters.yardName);
  const yardId = useRef(oldFilters.yardId);
  const [clientName, setClientName] = useState(oldFilters.clientName);
  const clientId = useRef(oldFilters.clientId);

  const { data: yardQuery } = useYardOptionsQuery(
    [
      'yards-short',
      'assigned-yards' +
        (yardCompanyId.current &&
        showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)
          ? yardCompanyId.current
          : clientId.current
          ? clientId.current
          : ''),
    ],
    { user, yardCompanyId: yardCompanyId.current, clientId: clientId.current, country, city },
  );

  useEffect(() => {
    if (showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)) {
      const yardCompanyObj = {
        label: showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)
          ? user.yardCompanyEmployee.yardCompany.legalName
          : '',
        id: showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)
          ? user.yardCompanyEmployee.yardCompanyId
          : '',
      };
      handleYardCompanySelect(yardCompanyObj);
    }

    if (showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user)) {
      const yardObj = {
        label: showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user)
          ? user.yardEmployee.yard.name
          : '',
        id: showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) ? user.yardEmployee.yardId : '',
      };
      handleYardSelect(yardObj);
    }

    if (showToRoles('client', user)) {
      const clientObj = {
        label: showToRoles('client', user) ? user.clientEmployee.client.legalName : '',
        id: showToRoles('client', user) ? user.clientEmployee.clientId : '',
      };
      handleClientSelect(clientObj);
    }
  }, []);

  const handleYardCompanySelect = (obj: any) => {
    yardCompanyId.current = obj.id;
    clientId.current = '';
    setYardCompanyName(obj.label);
    setCountry('');
    setCity('');
    setYardName('');
    setClientName('');

    const data = { yardCompanyId: obj.id, country: '', city: '', yardId: '', clientId: '' };
    setOldFilters({ ...oldFilters, yardCompanyName: obj.label, ...data });
    handleChange && handleChange(data);
  };

  const handleCountrySelect = (obj: any) => {
    const otherData: any = {};
    if (showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)) {
      otherData.clientId = '';
      clientId.current = '';
      setCity('');
      setYardName('');
      setClientName('');
    }
    if (showToRoles(['CLIENT_MANAGER', 'CLIENT_DISPATCHER'], user)) {
      setCity('');
      setYardName('');
    }

    setCountry(obj.label);

    const data = { country: obj.label, city: '', yardId: '', ...otherData };
    setOldFilters({ ...oldFilters, ...data });
    handleChange && handleChange(data);
  };

  const handleCitySelect = (obj: any) => {
    const otherData: any = {};
    if (showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)) {
      otherData.clientId = '';
      clientId.current = '';
      setYardName('');
      setClientName('');
    }
    if (showToRoles(['CLIENT_MANAGER', 'CLIENT_DISPATCHER'], user)) {
      setYardName('');
    }

    setCity(obj.label);

    const data = { city: obj.label, yardId: '', ...otherData };
    setOldFilters({ ...oldFilters, ...data });
    handleChange && handleChange(data);
  };

  const handleYardSelect = (obj: any) => {
    if (obj.isNew && showToRoles('client', user)) {
      sendContractModal.yardId = obj.id;
      sendContractModal.open();
      return;
    }

    const otherData: any = {};
    if (showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)) {
      otherData.clientId = '';
      clientId.current = '';
      setClientName('');
    }

    yardId.current = obj.id;
    setYardName(obj.label);

    const data = { yardId: obj.id, ...otherData };
    setOldFilters({ ...oldFilters, yardName: obj.label, ...data });
    handleChange && handleChange(data);
  };

  const handleClientSelect = (obj: any) => {
    clientId.current = obj.id;
    setClientName(obj.label);

    const data = { clientId: obj.id };
    setOldFilters({ ...oldFilters, clientName: obj.label, ...data });
    handleChange && handleChange(data);
  };

  return (
    <>
      {!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && (
        <SearchableSelect
          selectedOptionLabel={country}
          label={'Country'}
          placeholder={'Select Country'}
          onSelect={handleCountrySelect}
          options={yardQuery.country}
          selectedAreasEqaul={{ key: 'id', keys: [country] }}
          withClearButton
        />
      )}
      {!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && (
        <SearchableSelect
          selectedOptionLabel={city}
          label={'City'}
          placeholder={'Select City'}
          onSelect={handleCitySelect}
          options={yardQuery.city}
          selectedAreasEqaul={{ key: 'id', keys: [city] }}
          withClearButton
        />
      )}
      {!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && (
        <SearchableSelect
          selectedOptionLabel={yardName}
          label={isYardRequired ? 'Yard*' : 'Yard'}
          placeholder={'Select Yard'}
          onSelect={handleYardSelect}
          options={yardQuery.yard}
          selectedAreasEqaul={{ key: 'id', keys: [yardName] }}
          withClearButton
          error={isYardRequired && isSubmitClicked && !yardId.current}
        />
      )}
      {showToRoles('admin', user) && (
        <SearchableSelect
          selectedOptionLabel={clientName}
          label={isClientRequired ? 'Client*' : 'Client'}
          placeholder={'Select Client'}
          onSelect={handleClientSelect}
          queryFn={() => {
            if (
              (showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) ||
                !!yardCompanyId.current) &&
              !yardId.current
            )
              return () => [];

            if (yardId.current) return clientYardService.assignedClients({ id: yardId.current });

            if (showToRoles(['SUPER_ADMIN'], user)) return belongsToOptions.Client('', true);

            return () => [];
          }}
          queryKey={['clients', 'assigned-clients' + yardId.current]}
          selectedAreasEqaul={{ key: 'id', keys: [clientId.current] }}
          disabled={
            (showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) ||
              !!yardCompanyId.current) &&
            !yardId.current
          }
          withClearButton
          error={isClientRequired && isSubmitClicked && !clientId.current}
        />
      )}
    </>
  );
};
