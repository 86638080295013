export const colors = {
  '--gray-30': '#f5f6fa',
  '--gray-40': '#e6e9f4',
  '--gray-50': '#d7dbec',
  '--gray-60': '#a1a7c4',
  '--gray-70': '#7e84a3',
  '--gray-80': '#5a607f',
  '--gray-90': '#333752',
  '--gray-100': '#131523',

  '--primary-30': '#ecf2ff',
  '--primary-40': '#d9e4ff',
  '--primary-50': '#b6cbff',
  '--primary-60': '#89abff',
  '--primary-70': '#608dff',
  '--primary-80': '#4f81ff',
  '--primary-90': '#336dff',
  '--primary-100': '#1e5eff',

  '--secondary-30': '#f4e9fe',
  '--secondary-40': '#e9d3ff',
  '--secondary-50': '#d8b1ff',
  '--secondary-60': '#b97ff3',
  '--secondary-70': '#a75ef1',
  '--secondary-80': '#9645e9',
  '--secondary-90': '#8125df',
  '--secondary-100': '#6e0bd4',

  '--red-30': '#fde7ea',
  '--red-40': '#fcd5d9',
  '--red-50': '#fab3bc',
  '--red-60': '#f8919d',
  '--red-70': '#f45a6d',
  '--red-80': '#f34359',
  '--red-90': '#f12b43',
  '--red-100': '#f0142f',

  '--yellow-30': '#fff9e1',
  '--yellow-40': '#fff4c9',
  '--yellow-50': '#ffeca3',
  '--yellow-60': '#ffe582',
  '--yellow-70': '#ffe45f',
  '--yellow-80': '#ffd613',
  '--yellow-90': '#ffc700',
  '--yellow-100': '#f99600',

  '--green-30': '#daf9ec',
  '--green-40': '#c4f8e2',
  '--green-50': '#95f6cc',
  '--green-60': '#74efb9',
  '--green-70': '#48e9a5',
  '--green-80': '#25e191',
  '--green-90': '#1fd286',
  '--green-100': '#06a561',

  '--white': '#ffffff',
  '--transparent': '#00000000',
};

export enum ContainerStatusToChipColors {
  GATED_IN = 'green',
  GATED_OUT = 'red',
  GATE_OUT_REQUESTED = 'blue',
}

export enum GateStatusToChipColors {
  ACTIVE = 'green',
  EXPIRED = 'blue',
  COMPLETED = 'red',
}

export enum StoragePricingStatusToChipColors {
  ACTIVE = 'green',
  MISSING = 'red',
}

export enum ContainerIsLoadedToChipColors {
  LOADED = 'green',
  EMPTY = 'red',
}

export enum EmployeeAccountStatusToChipColors {
  ACTIVE = 'green',
  DEACTIVATED = 'grey',
}
