import { useQuery } from '../use-query';
import { toIsoDate } from '../../../utils';
import { reportsService } from '../../../services';
import { ReportsGetContainersQueryProps } from '../../../types';

export const useReportsGetContainersQuery = (props: ReportsGetContainersQueryProps) => {
  if (props.startDate) props.startDate = toIsoDate(props.startDate as Date, true);
  if (props.endDate) props.endDate = toIsoDate(props.endDate as Date, true);
  

  return useQuery(
    ['reports', JSON.stringify(props)],
    () => {
      return reportsService.getContainers(props).then((res) => {
        res.data.data = res.data.data.map((res) => {
          res.isLoadedText = res.isLoaded ? 'LOADED' : 'EMPTY';
          return res;
        });

        return res.data;
      });
    },
    {
      initialData: { data: [], totalCount: 0 },
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
    },
  );
};
