export const containerStatusLabel: any = {
  GATED_IN: 'Gated in',
  GATED_OUT: 'Gated out',
  GATE_OUT_REQUESTED: 'Gate out requested',
};

export const rolesLabel: any = {
  SUPER_ADMIN: 'Super Admin',
  YARD_COMPANY_ADMIN: 'Yard Company Admin',
  YARD_COMPANY_EMPLOYEE: 'Yard Company Employee',
  YARD_MANAGER: 'Yard Manager',
  YARD_DISPATCHER: 'Yard Dispatcher',
  CLIENT_MANAGER: 'Client Manager',
  CLIENT_DISPATCHER: 'Client Dispatcher',
};

export const signinRolesLabel: any = {
  YardCompanyAdmin: 'YARD_COMPANY_ADMIN',
  YardCompanyEmployee: 'YARD_COMPANY_EMPLOYEE',
  YardManager: 'YARD_MANAGER',
  YardDispatcher: 'YARD_DISPATCHER',
  ClientManager: 'CLIENT_MANAGER',
  ClientDispatcher: 'CLIENT_DISPATCHER',
};

export const viewCompanyLabel: any = {
  yardcompanies: 'Yard Company',
  yards: 'Yard Name',
  clients: 'Client Name',
};

export const viewCompanyTitle: any = {
  yardcompanies: 'Yard Company',
  yards: 'Yard',
  clients: 'Client Company',
};

export const employeesTableCompanyTitle: any = {
  YardCompany: 'Yard Company',
  Yard: 'Yard Name',
  Client: 'Client Company',
};

export const employeesViewLabel: any = {
  YardCompany: 'Yard Company',
  Yard: 'Yard',
  Client: 'Client',
};

export const employeesRolesLabel: any = {
  SuperAdmin: 'Super Admin',
  YardCompanyAdmin: 'Yard Company Admin',
  YardCompanyEmployee: 'Yard Company Employee',
  YardManager: 'Yard Manager',
  YardDispatcher: 'Yard Dispatcher',
  ClientManager: 'Client Manager',
  ClientDispatcher: 'Client Dispatcher',
};

export const clientYardModalLabel: any = {
  'add-clients': 'Add Clients',
  'reactivate-clients': 'Reactivate Clients',
  'deactivate-clients': 'Deactivate Clients',
  'add-yards': 'Add Yards',
  'reactivate-yards': 'Reactivate Yards',
  'deactivate-yards': 'Deactivate Yards',
};

export const clientYardModalPlaceholder: any = {
  'add-clients': 'Select Clients',
  'reactivate-clients': 'Select Clients',
  'deactivate-clients': 'Select Clients',
  'add-yards': 'Select Yards',
  'reactivate-yards': 'Select Yards',
  'deactivate-yards': 'Select Yards',
};

export const pricingFormLabels = {
  loaded: (containerType: string) => `${containerType} Loaded`,
  empty: (containerType: string) => `${containerType} Empty`,
  loadedCrane: (containerType: string) => `Crane Fee: ${containerType} Loaded`,
  emptyCrane: (containerType: string) => `Crane Fee: ${containerType} Empty`,
};

export enum ContainerStatusToChipLabels {
  GATED_IN = 'gated in',
  GATED_OUT = 'gated out',
  GATE_OUT_REQUESTED = 'gate out requested',
}

export enum GateStatusToChipLabels {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
}

export enum StoragePricingStatusToLabels {
  ACTIVE = 'active',
  MISSING = 'missing',
}

export enum ContainerIsLoadedToChipLabels {
  LOADED = 'loaded',
  EMPTY = 'empty',
}
