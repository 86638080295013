import { useMutation } from 'react-query';
import { gateModal } from '../../../design-system';
import { toIsoDate } from '../../../utils';
import { useStore } from '../../../context';
import { gateInsService, removeCache } from '../../../services';

export const useAddGateInMutation = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return useMutation(
    (props: any) => {
      props = props.map((obj: any) => {
        obj.authorId = user.id;

        return obj;
      });

      if (!props.length) return Promise.reject();

      props = {
        gateIns: props.map((obj: any) => {
          const otherData: any = {};
          if (obj.releaseNumber) otherData.releaseNumber = obj.releaseNumber;
          if (obj.comment) otherData.comment = obj.comment;
          if (obj.containerType) otherData.containerType = obj.containerType;
          if (obj.isLoaded) otherData.isLoaded = obj.isLoaded;

          return {
            containerNumber: obj.containerNumber,
            validFrom: toIsoDate(
              obj.validFrom.set({
                hour: 1,
                minute: 0,
                second: 0,
                millisecond: 0,
              }),
            ),
            validTo: toIsoDate(
              obj.validTo.set({
                hour: 23,
                minute: 0,
                second: 0,
                millisecond: 0,
              }),
            ),
            ...otherData,
          };
        }),
        clientId: props[0].clientId,
        yardId: props[0].yardId,
      };

      return gateInsService.add(props).then((res) => res.data);
    },
    {
      onSuccess: () => {
        removeCache(['gate-in', 'key']);
      },
    },
  );
};

export const useCompleteGateInMutation = () => {
  return useMutation(
    (id: string) => {
      const props: any = {
        driver: {
          firstName: gateModal.driverFirstName,
          lastName: gateModal.driverLastName,
          licensePlateNumber: gateModal.licensePlateNumber,
          phoneNumber: gateModal.phoneNumber,
        },
        containerType: gateModal.containerType,
      };

      Object.keys(props.driver).forEach((key) => {
        if (!props.driver[key]) delete props.driver[key];
      });
      if (!Object.keys(props.driver).length) delete props.driver;

      return gateInsService.complete({ id, props });
    },
    {
      onSuccess: () => {
        removeCache(['container', 'gate-in', 'key']);
      },
      onSettled: () => {
        Object.keys(gateModal).forEach((key) => {
          if (key !== 'open') delete gateModal[key];
        });
      },
    },
  );
};

export const useDeleteGateInMutation = () => {
  return useMutation(
    (id: string) => {
      return gateInsService.delete(id);
    },
    {
      onSuccess: () => {
        removeCache(['gate-in', 'key']);
      },
    },
  );
};
