import * as XLSX from 'xlsx';

interface ExportExcelProps {
  sheets: { sheetName: string; data: { [key: string]: string | number }[] }[];
  fileName: string;
}

export const exportExcel = ({ sheets, fileName }: ExportExcelProps) => {
  const excelBook = XLSX.utils.book_new();

  for (let i = 0; i < sheets.length; i++) {
    const excelSheet = XLSX.utils.json_to_sheet(sheets[i].data);

    const colWidth = 20;
    const cols = Object.keys(excelSheet).map(() => ({ wch: colWidth }));
    excelSheet['!cols'] = cols;

    XLSX.utils.book_append_sheet(excelBook, excelSheet, sheets[i].sheetName);
  }

  XLSX.writeFile(excelBook, `${fileName}.xlsx`);
};
