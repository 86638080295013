import React from 'react';
import styled, { css } from 'styled-components';
import { TableFilters, BelongsToFilterSelects } from '../../design-system';
import { showToRoles } from '../../utils';
import { FiltersSetStatesProp, User } from '../../types';

interface TableFiltersProps {
  isYardClientRole: boolean;
}

const TableFiltersBase = styled(TableFilters)<TableFiltersProps>`
  & > *:nth-child(1) {
    order: 1;
  }
  & > *:nth-child(5) {
    order: 2;
    grid-column: 1 / 3;
  }
  & > *:nth-child(2) {
    order: 3;
    grid-column: initial;
  }
  & > *:nth-child(3) {
    order: 4;
  }
  & > *:nth-child(4) {
    order: 5;
  }

  ${(props) =>
    props.isYardClientRole &&
    css`
      & > *:nth-child(2) {
        order: 2;
        grid-column: 1 / 3;
      }
    `}
`;

interface Props {
  user: User;
  setStates: FiltersSetStatesProp;
}

export const Filters: React.FC<Props> = ({ user, setStates }: Props) => {
  return (
    <TableFiltersBase
      isYardClientRole={
        !!showToRoles(
          ['YARD_MANAGER', 'YARD_DISPATCHER', 'CLIENT_MANAGER', 'CLIENT_DISPATCHER'],
          user,
        )
      }
    >
      <span className={'remove-dots-column'} />
      <BelongsToFilterSelects
        handleChange={(obj) => {
          setStates('force', obj);
        }}
      />
    </TableFiltersBase>
  );
};
